import {createRouter, createWebHistory} from "vue-router/dist/vue-router"
import store from "@/plugins/vuex/store"

const ifNotAuthorized = (to, from, next) => {
    if (store.getters.isAuthorized) {
        next('/')
    } else {
        next()
    }
}

const ifAuthorized = (to, from, next) => {
    if (store.getters.isAuthorized) {
        next()
    } else {
        next('/login?previousRoute=' + to.fullPath)
    }
}

const checkEmployer = (to, from, next) => {
    if (store.getters.isEmployer === null) {
        store.dispatch('fetchAboutMe')
            .then(() => {
                if (store.getters.getUser.isActive) {
                    if (store.getters.isEmployer || store.getters.getUser.roles.includes('ROLE_ADMIN')) {
                        next()
                    } else {
                        next('/')
                    }
                } else {
                    next('/confirmation')
                }
            })
            .catch(() => {
                next('/')
            });
    } else if (store.getters.getUser.isActive) {
        if (store.getters.isEmployer || store.getters.getUser.roles.includes('ROLE_ADMIN')) {
            next()
        } else {
            next('/')
        }
    } else {
        next('/confirmation')
    }
}

const checkEmployee = (to, from, next) => {
    if (store.getters.isEmployer === null) {
        store.dispatch('fetchAboutMe')
            .then(() => {
                if (store.getters.getUser.isActive) {
                    if (!store.getters.isEmployer) {
                        next()
                    } else {
                        next('/')
                    }
                } else {
                    next('/confirmation')
                }
            })
            .catch(() => {
                next('/')
            });
    } else if (store.getters.getUser.isActive) {
        if (!store.getters.isEmployer) {
            next()
        } else {
            next('/')
        }
    } else {
        next('/confirmation')
    }
}

const routes = [
    {
        path: '/',
        component: () => import('@/pages/MainPage.vue'),
    },
    {
        path: '/about-us',
        component: () => import('@/components/AboutComponent.vue'),

    },
    {
        path: '/chats',
        component: () => import('@/components/chat/ChatLayout.vue'),
        beforeEnter: ifAuthorized,
        children: [
            {
                path: '/chat/:id',
                component: () => import('@/components/chat/ChatComponent.vue'),
            }
        ]
    },
    {
        path: '/login',
        component: () => import('@/pages/AuthPage.vue'),
        beforeEnter: ifNotAuthorized,
        children: [
            {
                path: '/login',
                component: () => import('@/components/authorization/LoginComponent.vue'),
            },
            {
                path: '/registration',
                component: () => import('@/components/authorization/RegistrationComponent.vue'),
            },
            {
                path: '/forgot-password',
                component: () => import('@/components/authorization/ForgotPasswordComponent.vue'),
            },
            {
                path: '/reset-password/:token',
                component: () => import('@/components/authorization/ResetPassword.vue'),
            }
        ]
    },
    {
        path: '/confirmation/:code?',
        beforeEnter: ifAuthorized,
        component: () => import('@/components/authorization/ConfirmationComponent.vue'),
    },
    {
        path: '/resumes',
        component: () => import('@/pages/ItemsListPage.vue'),
        children: [
            {
                path: '/resumes/:pageNumber?',
                component: () => import('@/components/resume/ResumeAllComponent.vue'),
            },
            {
                path: '/vacancies/:pageNumber?',
                component: () => import('@/components/vacancy/VacancyAllComponent.vue'),
            },
            {
                path: '/companies/:pageNumber?',
                component: () => import('@/components/company/CompanyAllComponent.vue'),
            },
            {
                path: '/resume/:resumeId',
                component: () => import('@/components/resume/ResumeComponent.vue'),
                beforeEnter: ifAuthorized,
            },
            {
                path: '/vacancy/:vacancyId',
                component: () => import('@/components/vacancy/VacancyComponent.vue'),
                beforeEnter: ifAuthorized,
            },
            {
                path: '/company/:companyId',
                component: () => import('@/components/company/CompanyComponent.vue'),
            },
        ]
    },
    {
        path: '/profile',
        component: () => import('@/pages/ProfilePage.vue'),
        beforeEnter: ifAuthorized,
        children: [
            {
                path: '/profile',
                component: () => import('@/components/ProfileComponent.vue'),
            },
            {
                path: '/add-resume',
                component: () => import('@/components/resume/AddResumeComponent.vue'),
                beforeEnter: checkEmployee
            },
            {
                path: '/my-resumes/:pageNumber?',
                component: () => import('@/components/resume/MyResumesComponent.vue'),
                beforeEnter: checkEmployee
            },
            {
                path: '/my-resume-offers/:resumeId',
                component: () => import('@/components/resume/ResumeViewOfferComponent.vue'),
                beforeEnter: checkEmployee
            },
            {
                path: '/my-resume-offers',
                component: () => import('@/components/resume/ResumeOffersComponent.vue'),
                beforeEnter: checkEmployee
            },
            {
                path: '/edit-resume/:resumeId',
                component: () => import('@/components/resume/ResumeEditComponent.vue'),
                beforeEnter: checkEmployee
            },
            {
                path: '/resume-statistics/:resumeId',
                component: () => import('@/components/resume/ResumeStatistics.vue'),
                beforeEnter: checkEmployee
            },
            {
                path: '/my-vacancies/:pageNumber?',
                component: () => import('@/components/vacancy/MyVacanciesComponent.vue'),
                beforeEnter: checkEmployer
            },
            {
                path: '/archives',
                component: () => import('@/components/ArchivesComponent.vue'),
            },
            {
                path: '/my-vacancy-offers/:vacancyId',
                component: () => import('@/components/vacancy/VacancyViewOffersComponent.vue'),
                beforeEnter: checkEmployer
            },
            {
                path: '/my-vacancy-offers',
                component: () => import('@/components/vacancy/VacancyOffersComponent.vue'),
                beforeEnter: checkEmployer
            },
            {
                path: '/edit-vacancy/:vacancyId',
                component: () => import('@/components/vacancy/VacancyEditComponent.vue'),
                beforeEnter: checkEmployer
            },
            {
                path: '/add-vacancy',
                component: () => import('@/components/vacancy/VacancyAddComponent.vue'),
                beforeEnter: checkEmployer
            },
            {
                path: '/my-companies/:pageNumber?',
                component: () => import('@/components/company/MyCompaniesComponent.vue'),
                beforeEnter: checkEmployer
            },
            {
                path: '/user-edit-company/:id',
                component: () => import('@/components/company/CompanyEditComponent.vue'),
                beforeEnter: checkEmployer
            },
            {
                path: '/add-company',
                component: () => import('@/components/company/CompanyAddComponent.vue'),
                beforeEnter: checkEmployer
            },
            {
                path: '/favorites',
                component: () => import('@/components/FavoritesComponent.vue'),
            },
            {
                path: '/user-response',
                component: () => import('@/components/user/UserResponse.vue'),
            },
            {
                path: '/payment-to-add-company',
                component: () => import('@/components/payment/PaymentToAddCompany.vue'),
                beforeEnter: checkEmployer
            },
            {
                path: '/payment-to-add-vacancy/:companyId',
                component: () => import('@/components/payment/PaymentToAddVacancy.vue'),
                beforeEnter: checkEmployer
            },
            {
                path: '/payment-for-vacancy-top/:vacancyId',
                component: () => import('@/components/payment/PaymentForVacancyTop.vue'),
                beforeEnter: checkEmployer
            },
            {
                path: '/payment-for-company-top/:companyId',
                component: () => import('@/components/payment/PaymentForCompanyTop.vue'),
                beforeEnter: checkEmployer
            },
            {
                path: '/payment-for-resume-top/:resumeId',
                component: () => import('@/components/payment/PaymentForResumeTop.vue'),
                beforeEnter: checkEmployee
            },
            {
                path: '/payment-to-view-resume',
                component: () => import('@/components/payment/PaymentToViewResume.vue'),
            },
            {
                path: '/resume-send/:resumeSendId',
                component: () => import('@/components/vacancy/ResumeSendComponent.vue'),
            },
            {
                path: '/job-offer/:jobOfferId',
                component: () => import('@/components/resume/JobOfferComponent.vue'),
            },
        ]
    },
    {
        path: '/notFountPage',
        component: () => import('@/pages/NotFoundPage.vue'),

    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/pages/NotFoundPage.vue'),

    },

]

export default createRouter({
    history: createWebHistory(),
    scrollBehavior() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    routes
})
