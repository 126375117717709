import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import i18n from "@/locales/i18n"
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    actions: {
        fetchSelectedVacancies(context, vacanciesId = []) {
            let url = '';

            if (vacanciesId) {
                vacanciesId.map(item => {
                    url += '&vacancy.id[]=' + item
                })
            }

            return getRequests('/selected_vacancies?page=1' + url, 'updateSelectedVacancies', context)
        },
        fetchSelectedVacancy(context, vacancyId) {
            return getRequests('/selected_vacancies?vacancy.id=' + vacancyId, 'updateSelectedVacancy', context)
        },
        pushSelectedVacancy(context, vacancyId) {
            return postRequest('/selected_vacancies', vacancyId, 'updateSelectedVacancy', context)
                .catch((error) => {
                    if (error.response.status === 400) {
                        toast.error(i18n.global.t("vacancy.cantMarkAsFavoriteVacancy"))
                    }
                })
        },
        fetchMySelectedVacancies(context, page = 1) {
            return getRequests('/selected_vacancies/my?page=' + page, 'updateMySelectedVacancies', context)
        }
    },

    mutations: {
        updateSelectedVacancies(state, selectedVacancies) {
            state.selectedVacancies = selectedVacancies
        },
        updateSelectedVacancy(state, selectedVacancy) {
            state.selectedVacancy = selectedVacancy
        },
        updateMySelectedVacancies(state, mySelectedVacancies) {
            state.mySelectedVacancies = mySelectedVacancies
        }
    },

    state: {
        selectedVacancies: {
            models: [],
            totalItems: 0
        },
        selectedVacancy: {
            models: [],
            totalItems: 0
        },
        mySelectedVacancies: {
            models: [],
            totalItems: 0,
            itemsPage: 0
        },
    },

    getters: {
        getSelectedVacancies(state) {
            return state.selectedVacancies.models
        },
        getSelectedVacanciesCount(state) {
            return state.selectedVacancies.totalItems
        },
        getSelectedVacancy(state) {
            return state.selectedVacancy
        },
        getMySelectedVacancies(state) {
            return state.mySelectedVacancies
        }
    }
}