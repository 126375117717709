import postRequest from "@/plugins/requests/postRequest";
import axios from "@/plugins/vuex/axios";
import getRequest from "@/plugins/requests/getRequest";
import deleteRequest from "@/plugins/requests/deleteRequest";

export default {
    actions: {
        fetchChats(context, page) {
            return new Promise((resolve, reject) => {
                axios.get('/chats?page=' + page)
                    .then((response) => {
                        console.log(response)

                        let chats = {
                            models: response.data.filterChats['hydra:member'],
                            totalItems: response.data.filterChats['hydra:totalItems'],
                            totalUnSeenNumber: response.data.totalUnSeenNumber
                        }

                        context.commit('updateChats', chats)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchChat(context, chatId) {
            return getRequest('/chats/' + chatId, 'updateChat', context)
        },
        pushChat(context, data) {
            return postRequest('/chats', data, 'updateChat', context)
        },
        deleteChat(context, chat) {
            return deleteRequest('/chats/' + chat, context)
        }
    },
    mutations: {
        updateChats(state, chats) {
            state.chats = chats
        },
        updateChat(state, chat) {
            state.chat = chat
        }
    },
    state: {
        chats: {
            models: [],
            totalItems: 0,
            totalUnSeenNumber: 0
        },
        chat: {
            id: 0,
            user: null,
            participant: null,
            createdAt: null,
            unSeenNumber: 0
        },
    },
    getters: {
        getChats(state) {
            return state.chats
        },
        getChat(state) {
            return state.chat
        },
    }
}
