import postRequest from "@/plugins/requests/postRequest"

export default {
    actions: {
        addViewedVacancy(context, viewedResume) {
            return postRequest('/viewed_vacancies', viewedResume, 'updateViewedVacancy', context)
        }
    },

    mutations: {
        updateViewedVacancy() {}
    },
    state: {},
    getters: {}
}
