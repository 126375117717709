<template>
    <VBottomNavigation
        v-model="value"
        class="overflow-visible bg-red-lighten-1 d-sm-none"
        style="height: 60px"
        grow
    >
        <VBtn @click="$router.push('/')" class="px-1">
            <VIcon class="text-h4 mb-1">mdi-home</VIcon>
            {{ $t('home') }}
        </VBtn>

        <VBtn @click="$router.push('/user-response')" class="px-1">
            <VIcon class="text-h4 mb-1">mdi-file-account</VIcon>
            {{ $t('responses') }}
        </VBtn>

        <VBtn @click="$router.push(vacanciesOrResumes)" class="px-1">
            <VIcon class="text-h4 mb-1">mdi-file-document-multiple</VIcon>
            {{ isEmployer ? $t('resume.resumes') : $t('vacancy.vacancies') }}
        </VBtn>

        <VBtn @click="$router.push(offerPath)" class="px-1">
            <VIcon class="text-h4 mb-1">mdi-bell</VIcon>
            {{ $t('suggestions') }}
        </VBtn>
    </VBottomNavigation>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "BottomNavigationAuthComponent",
    data() {
        return {
            value: null,
            offerPath: '',
            vacanciesOrResumes: ''
        }
    },
    computed: {
        ...mapGetters(['isEmployer'])
    },
    methods: {
        ...mapActions(['fetchAboutMe']),
        checkToRoutePath() {
            if (this.$route.path === '/') {
                this.value = 0
            } else if (this.$route.path === '/resumes/' + this.$route.params.pageNumber) {
                this.value = 1
            } else if (this.$route.path === '/vacancies/' + this.$route.params.pageNumber) {
                this.value = 2
            } else if (this.$route.path === '/invites') {
                this.value = 3
            } else {
                this.value = null
            }
        }
    },
    mounted() {
        this.checkToRoutePath()
        this.fetchAboutMe()
            .then(() => {
                this.offerPath = this.isEmployer ? '/my-vacancy-offers' : '/my-resume-offers'
                this.vacanciesOrResumes = this.isEmployer ? '/resumes/1' : '/vacancies/1'
            })
    },
    watch: {
        '$route.path'() {
            this.checkToRoutePath()
        }
    }
}
</script>

<style scoped>
@media (max-width: 339px) {
    .v-btn {
        letter-spacing: 0 !important;
    }

    .text-h4 {
        font-size: 32px !important;
    }

    .v-bottom-navigation {
        font-size: 8px !important;
        min-width: 70px !important;
    }
}
</style>