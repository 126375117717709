import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import getRequest from "@/plugins/requests/getRequest";

export default {
    actions: {
        fetchTags(context, filterProps = {}){
            let tagUrl = ''

            if (filterProps.name) {
                tagUrl = '&name=' + filterProps.name
            }

            if (filterProps.user) {
                tagUrl += '&user=' + filterProps.user
            }

            return getRequests('/tags?page=' + filterProps.page + tagUrl , 'updateTags', context)
        },
        createTag(context, data) {
            return postRequest('/tags', data, 'updateTag', context)
        },
        fetchTagByName(context, data) {
            return getRequest('/tags/by_name?name=' + data, 'updateTag', context)
        }
    },

    mutations: {
        updateTags(state, tags) {
            state.tags = tags
        },
        updateTag(state, tag){
            state.tag = tag
        },
    },

    state: {
        tags: {
            models: [],
            totalItems: 0,
            itemsPage: 0
        },
        tag: {
            id: 0,
            name: ''
        },
    },

    getters: {
        getTags(state) {
            return state.tags
        },
        getTagByName(state) {
            return state.tag
        }
    }
}
