<template>
    <v-text-field
        v-if="$route.path === '/resumes/' + this.$route.params.pageNumber
            || $route.path === '/vacancies/' + this.$route.params.pageNumber
            || $route.path === '/companies/'  + this.$route.params.pageNumber"
        :variant="variant"
        :label="$t('search')"
        prepend-inner-icon="mdi-magnify"
        density="compact"
        :color="color"
        :hide-details="true"
        v-model="filterProps.name"
        @keyup.enter="searchFunc"
        @input="emptyInput"
        @click:prepend-inner="searchFunc"
    >
    </v-text-field>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "SearchComponent",
    props: {
        color: {
            type: String
        },
        variant: {
            type: String,
            default: 'underlined'
        }
    },
    data() {
        return {
            filterProps: {
                currentPage: Number(this.$route.params.pageNumber),
                name: '',
                company: '',
                minSalary: 0,
                cities: [],
                tags: []
            },
        }
    },
    computed: {
        ...mapGetters(['getFilterProps', 'isAuthorized', 'getResumes'])
    },
    methods: {
        ...mapActions(['fetchCompanies', 'fetchVacancies', 'fetchResumes', 'fetchSelectedResumes']),
        ...mapMutations(['updateFilterProps']),
        searchFunc() {
            if (this.$route.path === '/companies/' + this.$route.params.pageNumber) {
                this.updateCurrentProps()
                this.fetchCompanies(this.filterProps)
                    .then(() => {
                        this.$router.push('/companies/1')
                    })
            } else if (this.$route.path === '/vacancies/' + this.$route.params.pageNumber) {
                this.updateCurrentProps()
                this.fetchVacancies(this.filterProps)
                    .then(() => {
                        this.$router.push('/vacancies/1')
                    })
            } else if (this.$route.path === '/resumes/' + this.$route.params.pageNumber) {
                this.updateCurrentProps()
                this.fetchResumes(this.filterProps)
                    .then(() => {
                        if (this.isAuthorized) {
                            let resumesId = []
                            this.getResumes.map(item => {
                                resumesId.push(item.id)
                            })

                            this.fetchSelectedResumes(resumesId)
                            this.$router.push('/resumes/1')
                        }
                    })
            }
        },
        emptyInput() {
            if (this.$route.path === '/companies/' + this.$route.params.pageNumber && this.filterProps.name === '') {
                this.updateCurrentProps()
                this.fetchCompanies(this.filterProps)
                    .then(() => {
                        this.$router.push('/companies/1')
                    })
            } else if (this.$route.path === '/vacancies/' + this.$route.params.pageNumber && this.filterProps.name === '') {
                this.updateCurrentProps()
                this.fetchVacancies(this.filterProps)
                    .then(() => {
                        this.$router.push('/vacancies/1')
                    })
            } else if (this.$route.path === '/resumes/' + this.$route.params.pageNumber && this.filterProps.name === '') {
                this.updateCurrentProps()
                this.fetchResumes(this.filterProps)
                    .then(() => {
                        if (this.isAuthorized) {
                            let resumesId = []
                            this.getResumes.map(item => {
                                resumesId.push(item.id)
                            })

                            this.fetchSelectedResumes(resumesId)
                            this.$router.push('/resumes/1')
                        }
                    })
            }
        },
        updateCurrentProps() {
            this.filterProps.currentPage = 1
            this.filterProps.company = this.getFilterProps.company
            this.filterProps.minSalary = this.getFilterProps.minSalary
            this.filterProps.cities = this.getFilterProps.cities
            this.filterProps.tags = this.getFilterProps.tags
            this.updateFilterProps(this.filterProps)
        }
    },
    mounted() {
        this.filterProps.name = this.getFilterProps.name
    }
}
</script>

<style scoped>
</style>