import getRequests from "@/plugins/requests/getRequests"

export default {
    actions: {
        fetchPlanTypes(context){
            return getRequests('/plan_types', 'updatePlanTypes', context)
        },
    },

    mutations: {
        updatePlanTypes(state, planTypes) {
            state.planTypes = planTypes
        },
    },

    state: {
        planTypes: {
            models: [],
            totalItems: 0,
        },
    },

    getters: {
        getPlanTypes(state) {
            return state.planTypes.models
        },
    }
}
