import putRequest from "@/plugins/requests/putRequest"
import getRequest from "@/plugins/requests/getRequest"
import getRequests from "@/plugins/requests/getRequests"
import deleteRequest from "@/plugins/requests/deleteRequest"
import i18n from "@/locales/i18n"
import { useToast } from 'vue-toastification'
import postRequest from "@/plugins/requests/postRequest";
const toast = useToast()

export default {
    actions: {
        fetchVacancies(context, filterProps = {}) {
            let vacancyUrl = '';

            if (filterProps.name) {
                vacancyUrl += '&name=' + filterProps.name;
            }

            if (filterProps.company) {
                vacancyUrl += '&company=' + filterProps.company;
            }

            if (filterProps.minSalary) {
                vacancyUrl += '&minSalary=' + filterProps.minSalary;
            }

            if (filterProps.cities) {
                vacancyUrl += '&cities=' + filterProps.cities;
            }

            if (filterProps.tags) {
                vacancyUrl += '&tags=' + filterProps.tags;
            }

            if (filterProps.isAdmin) {
                vacancyUrl += '&isAdmin=' + filterProps.isAdmin;
            }

            return getRequests('/vacancies?page=' + filterProps.currentPage + vacancyUrl, 'updateVacancies', context)
        },
        fetchVacancy(context, vacancyId) {
            return getRequest('/vacancies/' + vacancyId, 'updateVacancy', context)
        },
        fetchMyVacancyByUser(context) {
            return getRequest('/vacancies/by_user', 'updateVacancy', context)
        },
        pushVacancy(context, data) {
            return postRequest('/vacancies', data, 'updateVacancy', context)
                .then(() => {
                    toast.success(i18n.global.t('publicStatus0'), {
                        timeout: 3000,
                    });
                })
        },
        fetchMyVacancies(context, filterProps = {}) {
            let vacancyUrl = '?page=' + filterProps.page;

            if (filterProps.isPublic) {
                vacancyUrl += '&isPublic=' + filterProps.isPublic;
            }

            return getRequests('/vacancies/my' + vacancyUrl, 'updateMyVacancies', context)
        },
        fetchSimilarVacancies(context, filterProps = {}) {
            let vacancyUrl = '?id=' + filterProps.id;

            if (filterProps.tags) {
                vacancyUrl += '&tags=' + filterProps.tags;
            }

            return getRequests('/vacancies/similar' + vacancyUrl, 'updateSimilarVacancies', context)
        },
        fetchVacancyArchives(context) {
            return getRequests('/vacancies/archives', 'updateVacancyArchives', context)
        },
        editArchiveVacancy(context, vacancy) {
            return putRequest('/vacancies/'+ vacancy.id + '/is_archive', vacancy.archive, 'updateVacancy', context)
        },
        fetchMyVacancy(context, vacancyId) {
            return getRequest('/vacancies/'+ vacancyId + '/my', 'updateVacancy', context)
        },
        editVacancy(context, vacancy) {
            return putRequest('/vacancies/' + vacancy.id, vacancy, 'updateVacancy', context)
                .then(() => {
                    toast.success(i18n.global.t('publicStatus0'), {
                        timeout: 3000,
                    });
                })
        },
        deleteVacancy(context, vacancyId) {
            return deleteRequest('/vacancies/' + vacancyId)
        },
    },

    mutations: {
        updateVacancies(state, vacancies) {
            state.vacancies = vacancies
        },
        updateFilterProps(state, filterProps) {
            state.filterProps = filterProps
        },
        updateVacancy(state, vacancy) {
            state.vacancy = vacancy
        },
        updateMyVacancies(state, myVacancies) {
            state.myVacancies = myVacancies
        },
        updateVacancyArchives(state, vacancyArchives) {
            state.vacancyArchives = vacancyArchives
        },
        updateSimilarVacancies(state, similarVacancies) {
            state.similarVacancies = similarVacancies
        },
    },

    state: {
        vacancies: {
            models: [],
            totalItems: 0,
            itemsPage: 0
        },
        filterProps: {
            currentPage: 1,
            name: '',
            company: '',
            minSalary: 0,
            cities: [],
            tags: []
        },
        vacancy: {
            id: 0,
            name: '',
            description: '',
            company: {},
            city: {},
            tags: [],
            createdAt: '',
            user: {},
            minSalary: 0,
            maxSalary: 0,
            currency: {},
            minExperience: 0,
            maxExperience: 0,
            requirement: '',
            terms: '',
            resumeSendsCount: 0,
            publicStatus: 0,
        },
        myVacancies: {
            models: [],
            totalItems: 0,
            itemsPage: 0
        },
        vacancyArchives: {
            models: [],
            totalItems: 0,
        },
        similarVacancies: {
            models: [],
            totalItems: 0
        }
    },

    getters: {
        getVacancies(state) {
            return state.vacancies
        },
        getFilterProps(state) {
            return state.filterProps
        },
        getVacancy(state) {
            return state.vacancy
        },
        getMyVacancies(state) {
            return state.myVacancies
        },
        getVacancyArchives(state) {
            return state.vacancyArchives
        },
        getSimilarVacancies(state){
            return state.similarVacancies
        }
    }
}
