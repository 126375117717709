<template>
    <VNavigationDrawer
        v-model="drawerValue"
        temporary
        location="right"
        theme="dark"
        class="d-block d-md-none bg-red-lighten-1"
    >
        <VListItem
        >
            <div class="text-center my-2">
                <VAvatar
                    color="white"
                    @click="goToPage('/profile')"
                >
                    <v-img
                        v-if="getUser.image"
                        :src="url + getUser.image.contentUrl"
                        cover
                    ></v-img>
                    <VIcon v-else color="red-lighten-1" icon="mdi-account" size="x-large"></VIcon>
                </VAvatar>
                <h3 @click="goToPage('/profile')" class="mt-1">{{ getUser.givenName + ' ' + getUser.familyName }}</h3>
                <p @click="goToPage('/profile')" class="text-caption mt-1">
                    {{ getUser.email }}
                </p>
            </div>
        </VListItem>

        <VDivider :thickness="2"></VDivider>

        <SearchComponent :variant="'plain'" class="px-4 pt-3 pb-1"/>

        <VDivider
            v-if="$route.path === '/resumes' || $route.path === '/vacancies' || $route.path === '/companies'"
            :thickness="2"
        ></VDivider>

        <VList density="comfortable" nav>
            <VListItem
                v-if="!getUser.isEmployer"
                @click="goToPage('/my-resumes/1')"
                prepend-icon="mdi-file-account-outline"
                :title="$t('resume.myResumes')"
                value="myResumes"
            >
            </VListItem>
            <VListItem
                v-if="!getUser.isEmployer"
                @click="goToPage('/add-resume')"
                prepend-icon="mdi-file-edit-outline"
                :title="$t('resume.addResume')"
                value="addResume"
            >
            </VListItem>
            <VListItem
                v-if="getUser.isEmployer"
                @click="goToPage('/my-companies/1' +
                 '')" prepend-icon="mdi-office-building-outline"
                :title="$t('company.myCompanies')"
                value="myCompanies"
            >
            </VListItem>
            <VListItem
                v-if="getUser.isEmployer"
                @click="check"
                prepend-icon="mdi-domain-plus"
                :title="$t('company.addCompany')"
                value="addCompany"
            >
            </VListItem>
            <VListItem
                v-if="getUser.isEmployer"
                @click="goToPage('/my-vacancies/1' +
                 '')" prepend-icon="mdi-file-document-multiple-outline"
                :title="$t('vacancy.myVacancies')"
                value="myVacancies"
            >
            </VListItem>
            <VListItem
                v-if="getUser.isEmployer"
                @click="goToPage('/add-vacancy')"
                prepend-icon="mdi-file-document-edit-outline"
                :title="$t('vacancy.addVacancy')"
                value="addVacancy"
            >
            </VListItem>
            <VListItem
                v-if="getUser.isEmployer"
                @click="goToPage('/archives')"
                prepend-icon="mdi-archive-outline"
                :title="$t('archives')"
                value="archive"
            >
            </VListItem>
            <VListItem
                @click="goToPage('/favorites')"
                prepend-icon="mdi-star-outline"
                :title="$t('favorites')"
                value="favorites"
            >
            </VListItem>
            <VListItem
                @click="goToPage('/user-response')"
                prepend-icon="mdi-message-reply-text-outline"
                :title="$t('responses')"
                value="answers"
            >
            </VListItem>
        </VList>

        <VDivider :thickness="2"></VDivider>

        <VList density="comfortable" nav>
            <VListItem
                @click="logout"
                prepend-icon="mdi-logout"
                :title="$t('logout')"
                value="logout"
            >
            </VListItem>
        </VList>
    </VNavigationDrawer>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import SearchComponent from "@/components/header/SearchComponent.vue";

export default {
    name: "SideNavbarForSmallComponent",
    components: {SearchComponent},
    props: {
        drawer: {
            type: Boolean
        },
    },
    data() {
        return {
            url: process.env.VUE_APP_API_URL,
            drawerValue: false
        }
    },
    methods: {
        ...mapMutations(['clearTokens']),
        ...mapActions(['changeUserStatus']),
        logout() {
            this.changeUserStatus({id: this.getUser.id, status: false})
                .finally( () => {
                    this.clearTokens()
                    this.$router.push('/')
                    this.$emit('changeDrawer')
                })
        },
        goToPage(route) {
            this.$router.push(route)
            this.$emit('changeDrawer')
        },
        check() {
            if (this.getUser.availableCompanies > 0) {
                this.$router.push('/add-company')
            } else {
                this.$router.push('/payment-to-add-company')
            }
        },
    },
    computed: {
        ...mapGetters(['getUser'])
    },
    watch: {
        'drawer'() {
            this.drawerValue = this.drawer
        },
        'drawerValue'() {
            if (this.drawerValue === false) {
                this.$emit('changeDrawer')
            }
        }
    },
}
</script>

<style scoped>
</style>
