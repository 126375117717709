import putRequest from "@/plugins/requests/putRequest"
import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import getRequest from "@/plugins/requests/getRequest";

export default {
    actions: {
        fetchResumeSends(context, filterProps = {}){
            let resumeSendUrl = '&order%5Bstatus%5D=asc';

            if (filterProps.vacancy) {
                resumeSendUrl += '&vacancy=' + filterProps.vacancy;
            }
            if (filterProps.vacanciesId) {
                filterProps.vacanciesId.map(item => {
                    resumeSendUrl += '&vacancy.id[]=' + item
                })
            }

            return getRequests('/resume_sends?page=' + filterProps.page + resumeSendUrl, 'updateResumeSends', context)
        },
        fetchResumeSendsResponse(context){
            return getRequests('/resume_sends/response', 'updateResumeSendsResponse', context)
        },
        fetchNewResumeSendsCount(context){
            return getRequests('/resume_sends/new', 'updateNewOffersCount', context)
        },
        fetchNewJobOffersCount (context){
            return getRequests('/job_offers/new', 'updateNewOffersCount', context)
        },
        resumeSendUpdate(context, resumeSend){
            return putRequest('/resume_sends/' + resumeSend.id, resumeSend, 'updateResumeSend', context)
        },
        addResumeSend(context, selectedResumeSend) {
            return postRequest('/resume_sends', selectedResumeSend, 'updateResumeSend', context)
        },
        fetchResumeSend(context, resumeSendId) {
            return getRequest('/resume_sends/' + resumeSendId, 'updateResumeSend', context)
        }
    },

    mutations: {
        updateResumeSends(state, resumeSends) {
            state.resumeSends = resumeSends
        },
        updateResumeSendsResponse(state, resumeSendsResponse) {
            state.resumeSendsResponse = resumeSendsResponse
        },
        updateNewOffersCount(state, newOffersCount) {
            state.newOffersCount = newOffersCount
        },
        updateResumeSend(state, resumeSend) {
            state.resumeSend = resumeSend
        },
    },
    state: {
        resumeSends: {
            models: [],
            totalItems: 0
        },
        resumeSendsResponse: {
            models: [],
            totalItems: 0
        },
        newOffersCount:{
            totalItems: 0
        },
        resumeSend:{
            id: 0,
            text: '',
            status: 0,
            resume: {
                id: 0,
                name: '',
                wantedSalary: 0,
                currency: {},
                user: {
                    id: 0,
                    givenName: '',
                    familyName: ''
                },
                city: {
                    id: 0,
                    name: ''
                },
                tags: []
            },
            vacancy: {
                id: 0,
                name: '',
                user: {
                    id: 0,
                    givenName: '',
                    familyName: ''
                },
                city: {
                    id: 0,
                    name: ''
                },
                tags: []
            },
        },
    },
    getters: {
        getResumeSends(state) {
            return state.resumeSends
        },
        getResumeSendsResponse(state) {
            return state.resumeSendsResponse
        },
        getNewOffersCount(state) {
            return state.newOffersCount.totalItems
        },
        getResumeSend(state) {
            return state.resumeSend
        },
    }
}
