<template>
    <VBottomNavigation
        v-model="value"
        class="overflow-visible bg-red-lighten-1 d-sm-none"
        style="height: 60px"
        grow
    >
        <VBtn @click="$router.push('/login')" style="width: 33%" class="px-1">
            <VIcon class="text-h4 mb-1">mdi-login</VIcon>
            {{ $t('login') }}
        </VBtn>

        <VBtn @click="$router.push('/registration')" style="width: 34%" class="px-1">
            <VIcon class="text-h4 mb-1">mdi-account-plus</VIcon>
            {{ $t('registration') }}
        </VBtn>

        <VBtn @click="$router.push('/about-us')" style="width: 33%" class="px-1">
            <VIcon class="text-h4 mb-1">mdi-information</VIcon>
            {{ $t('aboutUs') }}
        </VBtn>
    </VBottomNavigation>
</template>

<script>
export default {
    name: "BottomNavigationNoAuthComponent",
    data() {
        return {
            value: null
        }
    },
    methods: {
        checkToRoutePath() {
            if (this.$route.path === '/login') {
                this.value = 0
            } else if (this.$route.path === '/registration') {
                this.value = 1
            } else if (this.$route.path === '/about-us') {
                this.value = 2
            } else {
                this.value = null
            }
        }
    },
    mounted() {
        this.checkToRoutePath()
    },
    watch: {
        '$route.path'() {
            this.checkToRoutePath()
        }
    }
}
</script>

<style scoped>
@media (max-width: 359px) {
    .v-bottom-navigation {
        font-size: 10px !important;
    }

    .v-btn {
        letter-spacing: 0 !important;
    }
}

@media (max-width: 339px) {
    .text-h4 {
        font-size: 32px !important;
    }

    .v-bottom-navigation {
        font-size: 8px !important;
    }
}
</style>