import postRequest from "@/plugins/requests/postRequest"

export default {
    actions: {
        pushBoughtTopCompany(context, data) {
            return postRequest('/bought_tops/company', data, 'updateBoughtTop', context)
        },
        pushBoughtTopVacancy(context, data) {
            return postRequest('/bought_tops/vacancy', data, 'updateBoughtTop', context)
        },
        pushBoughtTopResume(context, data) {
            return postRequest('/bought_tops/resume', data, 'updateBoughtTop', context)
        },
    },

    mutations: {
        updateBoughtTop(state, boughtTop) {
            state.boughtTop = boughtTop
        },
    },

    state: {
        boughtTop: {
            id: 0,
            paymeTransactionId: null,
            planTop: {
                price: null
            }
        },
    },

    getters: {
        getBoughtTop(state) {
            return state.boughtTop
        },
    }
}
