import postRequest from "@/plugins/requests/postRequest"

export default {
    actions: {
        pushBoughtVacancy(context, data) {
            return postRequest('/bought_vacancies', data, 'updateBoughtVacancy', context)
        },
    },

    mutations: {
        updateBoughtVacancy(state, boughtVacancy) {
            state.boughtVacancy = boughtVacancy
        },
    },

    state: {
        boughtVacancy: {
            id: 0,
            paymeTransactionId: null,
            planType: {
                price: null
            }
        },
    },

    getters: {
        getBoughtVacancy(state) {
            return state.boughtVacancy
        },
    }
}