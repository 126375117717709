import getRequests from "@/plugins/requests/getRequests"
import deleteRequest from "@/plugins/requests/deleteRequest"
import getRequest from "@/plugins/requests/getRequest"
import putRequest from "@/plugins/requests/putRequest"
import i18n from "@/locales/i18n"
import { useToast } from 'vue-toastification'
import postRequest from "@/plugins/requests/postRequest"
import axios from "@/plugins/vuex/axios";
const toast = useToast()

export default {
    actions: {
        fetchResumes(context, filterProps = {}) {
            let resumeUrl = '';

            if (filterProps.name) {
                resumeUrl += '&name=' + filterProps.name;
            }

            if (filterProps.minSalary) {
                resumeUrl += '&minSalary=' + filterProps.minSalary;
            }

            if (filterProps.cities) {
                resumeUrl += '&cities=' + filterProps.cities;
            }

            if (filterProps.tags) {
                resumeUrl += '&tags=' + filterProps.tags;
            }

            if (filterProps.isAdmin) {
                resumeUrl += '&isAdmin=' + filterProps.isAdmin;
            }

            return new Promise((resolve, reject) => {
                axios.get('/resumes?page=' + filterProps.currentPage + resumeUrl)
                    .then((response) => {
                        console.log(response)

                        let resumes = {
                            models: response.data.resumes['hydra:member'],
                            totalItems: response.data['totalItems'],
                            itemsPage: response.data['itemsPage']
                        }

                        context.commit('updateResumes', resumes)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchResume(context, resumeId) {
            return getRequest('resumes/' + resumeId, 'updateResume', context)
        },
        fetchMyResumeByUser(context) {
            return getRequest('resumes/by_user', 'updateResume', context)
        },
        fetchMyResumes(context, filterProps = {}){
            let resumeUrl = '?page=' + filterProps.page;

            if (filterProps.isPublic) {
                resumeUrl += '&isPublic=' + filterProps.isPublic;
            }

            return getRequests('/resumes/my_resumes' + resumeUrl, 'updateMyResumes', context)
        },
        fetchMyResume(context, resumeId) {
            return getRequest('/resumes/'+ resumeId + '/my', 'updateResume', context)
        },
        fetchSimilarResumes(context, filterProps = {}) {
            let resumeUrl = '?id=' + filterProps.id;

            if (filterProps.tags) {
                resumeUrl += '&tags=' + filterProps.tags;
            }

            return getRequests('/resumes/similar' + resumeUrl, 'updateSimilarResumes', context)
        },
        editResume(context, resume) {
            return putRequest('/resumes/' + resume.id, resume, 'updateResume', context)
                .then(() => {
                    toast.success(i18n.global.t('publicStatus0'), {
                        timeout: 3000,
                    });
                })
        },
        editAddTopResume(context, resume) {
            return putRequest('/resumes/'+ resume.id + '/add_top', resume, 'updateResume', context)
                .then(() => {
                    toast.success(i18n.global.t('tariffInfo'), {
                        timeout: 3000,
                    });
                })
        },
        createResume(context, resume) {
            return postRequest('/resumes', resume, 'updateResume', context)
                .then(() => {
                    toast.success(i18n.global.t('publicStatus0'), {
                        timeout: 3000,
                    });
                })
        },
        createResumeJob(context, resumeJob) {
            return postRequest('/resumejobs', resumeJob, 'updateResumeJobs', context)
        },
        deleteResume(context, resumeId) {
            return deleteRequest('resumes/' + resumeId)
        },
        makeResumeSeen(context, resumeId) {
            return getRequest('/resumes/'+ resumeId + '/has_seen', 'updateMakeResumeSeen', context)
        },
        fetchResumeVisits(context, data = {}) {
            return getRequest('/resumes/'+ data.resumeId + '/visits?time_zone=' + data.timeZone, 'updateResumeVisits', context)
        },
        fetchResumeArchives(context) {
            return getRequests('/resumes/archived', 'updateResumeArchives', context)
        },
        editArchiveResume(context, resume) {
            return putRequest('/resumes/'+ resume.id + '/is_archive', resume.archive, 'updateResume', context)
        },
    },

    mutations: {
        updateResumes(state, resumes) {
            state.resumes = resumes
        },
        updateResume(state, resume) {
            state.resume = resume
        },
        updateMyResumes(state, myResumes) {
            state.myResumes = myResumes
        },
        updateResumeJobs() {},
        updateMakeResumeSeen() {},
        updateResumeVisits(state, visit) {
            state.visit = visit
        },
        updateResumeArchives(state, resumeArchives) {
            state.resumeArchives = resumeArchives
        },
        updateSimilarResumes(state, similarResumes) {
            state.similarResumes = similarResumes
        },
    },

    state: {
        resumes: {
            models: [],
            totalItems: 0,
            itemsPage: 0
        },
        resume: {
            '@id': null,
            id: 0,
            name: null,
            description: null,
            createdAt: null,
            tags: [],
            language: [],
            resumejobs: [],
            wantedSalary: null,
            phone: null,
            answerCallFrom: null,
            answerCallTo: null,
            isRelocate: null,
            webSiteLink: null,
            city: {},
            image: {},
            user: {},
            currency: {},
            unseenJobOffersCount: 0,
            likesCount: 0,
            viewsCount: 0,
            publicStatus: 0
        },
        myResumes: {
            models: [],
            totalItems: 0,
            itemsPage: 0
        },
        visit: {
            visitsDaily: [],
            visitsWeekly: [],
            visitsMonthly: [],
            companyVisits: [],
            newViewsCount: 0
        },
        resumeArchives: {
            models: [],
            totalItems: 0,
            itemsPage: 0
        },
        similarResumes: {
            models: [],
            totalItems: 0
        },
    },

    getters: {
        getResumes(state) {
            return state.resumes.models
        },
        getResumesCount(state) {
            return state.resumes.totalItems
        },
        getResumesItemsPage(state) {
            return state.resumes.itemsPage
        },
        getResume(state) {
            return state.resume
        },
        getMyResumes (state) {
            return state.myResumes
        },
        getResumeVisits(state) {
            return state.visit
        },
        getResumeArchives(state) {
            return state.resumeArchives
        },
        getSimilarResumes(state) {
            return state.similarResumes
        },
    }
}
