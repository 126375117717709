<template>
    <VCard :ripple="false" class="d-none d-md-block cursor_default" @click="$emit('changeDrawer')">
        <VCardText>
            <div class="mx-auto">
                <div class="text-center">
                    <router-link to="/profile">
                        <VAvatar :color="getUser.image ? 'white' : 'red-lighten-1'">
                            <v-img
                                v-if="getUser.image"
                                :src="url + getUser.image.contentUrl"
                                cover
                            ></v-img>
                            <v-icon v-else icon="mdi-account" size="x-large"></v-icon>
                        </VAvatar>
                    </router-link>
                    <router-link to="/profile">
                        <h3 class="mt-1">{{ getUser.givenName + ' ' + getUser.familyName }}</h3>
                    </router-link>
                    <router-link to="/profile"><p class="text-caption mt-1">{{ getUser.email }}</p></router-link>
                </div>

               <div v-if="!getUser.isEmployer">
                   <VDivider class="mb-1 mt-4"></VDivider>
                   <div class="cursor-pointer">
                       <router-link to="/my-resumes/1">
                           <VBtn
                               rounded
                               variant="plain"
                               prepend-icon="mdi-file-account-outline"
                               size="x-large"
                               density="comfortable"
                           >
                               {{ $t('resume.myResumes') }}
                           </VBtn>
                       </router-link>
                   </div>
               </div>

                    <div v-if="!getUser.isEmployer">
                        <VDivider class="my-1"></VDivider>
                        <div class="cursor-pointer">
                            <router-link to="/add-resume">
                                <VBtn
                                    rounded
                                    variant="plain"
                                    prepend-icon="mdi-file-edit-outline"
                                    size="x-large"
                                    density="comfortable"
                                >
                                    {{ $t('resume.addResume') }}
                                </VBtn>
                            </router-link>
                        </div>
                    </div>
                <div v-if="getUser.isEmployer">
                    <VDivider class="my-1"></VDivider>
                    <div class="cursor-pointer">
                        <router-link to="/my-companies/1">
                            <VBtn
                                rounded
                                variant="plain"
                                prepend-icon="mdi-office-building-outline"
                                size="x-large"
                                density="comfortable"
                            >
                                {{ $t('company.myCompanies') }}
                            </VBtn>
                        </router-link>
                    </div>
                </div>

                <div v-if="getUser.isEmployer">
                    <VDivider class="my-1"></VDivider>
                    <div class="cursor-pointer" @click="check">
                        <a>
                            <VBtn
                                rounded
                                variant="plain"
                                prepend-icon="mdi-domain-plus"
                                size="x-large"
                                density="comfortable"
                            >
                                {{ $t('company.addCompany') }}
                            </VBtn>
                        </a>
                    </div>
                </div>

                <div v-if="getUser.isEmployer">
                    <VDivider class="my-1"></VDivider>
                    <div class="cursor-pointer">
                        <router-link to="/my-vacancies/1">
                            <VBtn
                                rounded
                                variant="plain"
                                prepend-icon="mdi-file-document-multiple-outline"
                                size="x-large"
                                density="comfortable"
                            >
                                {{ $t('vacancy.myVacancies') }}
                            </VBtn>
                        </router-link>
                    </div>
                </div>

                <div v-if="getUser.isEmployer">
                    <VDivider class="my-1"></VDivider>
                    <div class="cursor-pointer">
                        <router-link to="/add-vacancy">
                            <VBtn
                                rounded
                                variant="plain"
                                prepend-icon="mdi-file-document-edit-outline"
                                size="x-large"
                                density="comfortable"
                            >
                                {{ $t('vacancy.addVacancy') }}
                            </VBtn>
                        </router-link>
                    </div>
                </div>

                <VDivider class="my-1"></VDivider>
                <div class="cursor-pointer">
                    <router-link to="/archives">
                        <VBtn
                            rounded
                            variant="plain"
                            prepend-icon="mdi-archive-outline"
                            size="x-large"
                            density="comfortable"
                        >
                            {{ $t('archives') }}
                        </VBtn>
                    </router-link>
                </div>

                <VDivider class="my-1"></VDivider>

                <div class="cursor-pointer d-flex justify-start">
                    <router-link to="/favorites">
                        <VBtn
                            rounded
                            variant="plain"
                            prepend-icon="mdi-star-outline"
                            size="x-large"
                            density="comfortable"
                        >
                            {{ $t('favorites') }}
                        </VBtn>
                    </router-link>
                </div>
                <VDivider class="my-1"></VDivider>

                <div class="cursor-pointer">
                    <router-link to="/user-response">
                        <VBtn
                            rounded
                            variant="plain"
                            prepend-icon="mdi-message-reply-text-outline"
                            size="x-large"
                            density="comfortable"
                        >
                            {{ $t('responses') }}
                        </VBtn>
                    </router-link>
                </div>
                <VDivider class="my-1"></VDivider>
                <div class="cursor-pointer" @click="logout">
                    <VBtn
                        rounded
                        variant="plain"
                        prepend-icon="mdi-logout"
                        size="x-large"
                        density="comfortable"
                        color="red"
                    >
                        {{ $t('logout') }}
                    </VBtn>
                </div>
            </div>
        </VCardText>
    </VCard>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "SideNavbarForMediumComponent",
    data() {
        return {
            url: process.env.VUE_APP_API_URL,
        }
    },
    computed: {
        ...mapGetters(['getUser'])
    },
    methods: {
        ...mapMutations(['clearTokens']),
        ...mapActions(['changeUserStatus']),
        logout() {
            this.changeUserStatus({id: this.getUser.id, status: false})
                .finally(() => {
                    this.clearTokens()
                    this.$router.push('/')
                    this.$emit('changeDrawer')
                })
        },
        check() {
            if (this.getUser.availableCompanies > 0) {
                this.$router.push('/add-company')
            } else {
                this.$router.push('/payment-to-add-company')
            }
        },
    },
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.cursor-pointer:hover > a .v-btn--size-x-large {
    color: rgb(239, 83, 80);
}

.v-btn--variant-plain {
    opacity: 1;
    color: #606060;
}
</style>
