import {createApp} from 'vue'
import App from './App.vue'
import store from '@/plugins/vuex/store'
import router from "@/plugins/vuex/router"
import vuetify from './plugins/vuetify'
import i18n from "@/locales/i18n"
import {loadFonts} from './plugins/webfontloader'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import {options} from './plugins/toastOptions'
import CKEditor from '@ckeditor/ckeditor5-vue'
import VueApexCharts from "vue3-apexcharts"

loadFonts()

createApp(App)
    .use(VueApexCharts)
    .use(Toast, options)
    .use(i18n)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(CKEditor)
    .mount('#app')
