<template>
    <VCard>
        <SideNavbarForSmallComponent v-model:drawer="drawer" @changeDrawer="changeDrawer"/>

        <VMain class="d-flex flex-column" style="min-height: 100vh">
            <!--        HEADER START        -->
            <VAppBar :elevation="4" :density="densityValue">

                <template v-slot:prepend>
                    <div class="pl-md-14 pl-4 d-flex align-center">
                        <router-link class="d-flex align-center" to="/">
                            <svg width="82" height="40" viewBox="0 0 82 40">
                                <path
                                    d="M15.7183 0H74.425C78.6085 0 82 3.39147 82 7.57506V32.194C82 36.3776 78.6085 39.7691 74.425 39.7691H23.2933C19.1097 39.7691 15.7183 36.3776 15.7183 32.194V0Z"
                                    fill="#DB4141"/>
                                <path
                                    d="M3.46817 27.2494H1.38115V15.0431H3.46817V27.2494ZM1.21193 11.8054C1.21193 11.467 1.31346 11.1812 1.51652 10.9481C1.7271 10.7149 2.03546 10.5983 2.44158 10.5983C2.8477 10.5983 3.15606 10.7149 3.36664 10.9481C3.57722 11.1812 3.68251 11.467 3.68251 11.8054C3.68251 12.1439 3.57722 12.4259 3.36664 12.6515C3.15606 12.8772 2.8477 12.99 2.44158 12.99C2.03546 12.99 1.7271 12.8772 1.51652 12.6515C1.31346 12.4259 1.21193 12.1439 1.21193 11.8054ZM9.65028 12.0875V15.0431H11.9291V16.6564H9.65028V24.2261C9.65028 24.7149 9.75181 25.0834 9.95487 25.3316C10.1579 25.5723 10.5039 25.6926 10.9927 25.6926C11.2334 25.6926 11.5643 25.6475 11.9855 25.5572V27.2494C11.4365 27.3998 10.9025 27.475 10.3836 27.475C9.45097 27.475 8.74778 27.193 8.27397 26.629C7.80016 26.0649 7.56325 25.2639 7.56325 24.2261V16.6564H5.34085V15.0431H7.56325V12.0875H9.65028Z"
                                    fill="black"/>
                                <path
                                    d="M36.0588 24.3727L38.4053 15.0431H40.4923L36.9388 27.2494H35.2466L32.2796 17.9988L29.3916 27.2494H27.6995L24.1572 15.0431H26.2329L28.6358 24.1809L31.4787 15.0431H33.1596L36.0588 24.3727ZM42.0717 21.0335C42.0717 19.8377 42.3049 18.7622 42.7712 17.807C43.245 16.8519 43.8993 16.1149 44.7341 15.5959C45.5764 15.077 46.5353 14.8175 47.6108 14.8175C49.2729 14.8175 50.6154 15.3929 51.6382 16.5435C52.6685 17.6942 53.1837 19.2247 53.1837 21.135V21.2817C53.1837 22.4699 52.9543 23.5379 52.4956 24.4855C52.0443 25.4256 51.3938 26.1589 50.5439 26.6854C49.7016 27.2118 48.7314 27.475 47.6334 27.475C45.9788 27.475 44.6363 26.8997 43.606 25.749C42.5831 24.5983 42.0717 23.0754 42.0717 21.1801V21.0335ZM44.17 21.2817C44.17 22.6354 44.4821 23.7222 45.1064 24.5419C45.7381 25.3617 46.5804 25.7716 47.6334 25.7716C48.6938 25.7716 49.5361 25.3579 50.1603 24.5306C50.7846 23.6958 51.0967 22.5301 51.0967 21.0335C51.0967 19.6948 50.7771 18.6118 50.1378 17.7845C49.506 16.9497 48.6637 16.5323 47.6108 16.5323C46.5804 16.5323 45.7494 16.9422 45.1176 17.7619C44.4859 18.5817 44.17 19.7549 44.17 21.2817ZM61.701 16.9158C61.3852 16.8632 61.043 16.8369 60.6744 16.8369C59.3057 16.8369 58.3768 17.4197 57.888 18.5854V27.2494H55.801V15.0431H57.8316L57.8654 16.4533C58.5498 15.3628 59.52 14.8175 60.776 14.8175C61.1821 14.8175 61.4904 14.8702 61.701 14.9755V16.9158ZM67.037 21.5975L65.7284 22.9626V27.2494H63.6414V9.92148H65.7284V20.4017L66.8453 19.0593L70.647 15.0431H73.1853L68.4359 20.1423L73.7381 27.2494H71.2901L67.037 21.5975Z"
                                    fill="#F9F9F9"/>
                            </svg>
                        </router-link>

                        <!--     Rezumes, Vacancies, Companies START     -->
                        <div v-if="isAuthorized && $route.path === '/'" class="d-sm-flex d-none align-center ml-7">
                            <div>
                                <router-link
                                    class="mx-md-3 mx-sm-1 px-md-2 px-sm-1 py-md-3 py-sm-2 title"
                                    to="/resumes/1"
                                >
                                    {{ $t('resume.resumes') }}
                                </router-link>
                            </div>
                            <div>
                                <router-link
                                    class="mx-md-3 mx-sm-1 px-md-2 px-sm-1 py-md-3 py-sm-2 title"
                                    to="/vacancies/1"
                                >
                                    {{ $t('vacancy.vacancies') }}
                                </router-link>
                            </div>
                            <div>
                                <router-link class="mx-md-3 mx-sm-1 px-md-2 px-sm-1 py-md-3 py-sm-2 title"
                                             to="/companies/1"
                                >
                                    {{ $t('company.companies') }}
                                </router-link>
                            </div>
                        </div>
                        <!--   Rezumes, Vacancies, Companies END    -->
                    </div>
                </template>

                <template v-slot:default>
                    <div v-if="isAuthorized && $route.path !== '/'" class="search_div d-none d-md-block">
                        <SearchComponent :color="'red-lighten-1'"/>
                    </div>
                </template>

                <!--    MENU    -->
                <template v-slot:append>

                    <NotificationBellComponent v-if="isAuthorized"/>
                    <LanguageSwitcherComponent class="mr-2"/>

                    <!--            LOGIN & SIGN UP START            -->
                    <div v-if="!isAuthorized" class="d-sm-flex d-none align-center">
                        <div>
                            <router-link to="/registration" class="mx-sm-3 mx-1 px-sm-2 px-1 py-sm-3 py-2 title login_title">
                                {{ $t('registration') }}
                            </router-link>
                        </div>
                        <div>
                            <router-link to="/login" class="mx-sm-3 mx-1 px-sm-2 px-1 py-sm-3 py-2 title login_title">
                                {{ $t('login') }}
                            </router-link>
                        </div>
                    </div>
                    <!--            LOGIN & SIGN UP END            -->

                    <!--             PROFILE BTN START           -->
                    <div v-if="isAuthorized" class="pr-md-14 pr-4 align-center">
                        <VMenu
                            min-width="200px"
                            rounded
                        >
                            <template v-slot:activator="{ props }">
                                <VBtn
                                    icon
                                    v-bind="props"
                                    @click.stop="drawer = true"
                                >
                                    <VAvatar :color="getUser.image ? 'white' : 'red-lighten-1'">
                                        <v-img
                                            v-if="getUser.image"
                                            :src="url + getUser.image.contentUrl"
                                            cover
                                        ></v-img>
                                        <v-icon v-else icon="mdi-account" size="x-large"></v-icon>
                                    </VAvatar>
                                </VBtn>
                            </template>
                            <!--    Medium display menu START    -->
                            <SideNavbarForMediumComponent @changeDrawer="changeDrawer"/>
                            <!--    Medium display menu END    -->
                        </VMenu>
                    </div>
                </template>
            </VAppBar>
            <!--        HEADER END        -->
            <slot></slot>
            <FooterRow v-if="!this.$route.path.includes('/chat')"/>
        </VMain>

        <BottomNavigationAuthComponent v-if="isAuthorized"/>
        <BottomNavigationNoAuthComponent v-else/>
    </VCard>
</template>

<script>
import {mapGetters} from "vuex";
import LanguageSwitcherComponent from "@/components/header/LanguageSwitcherComponent.vue";
import NotificationBellComponent from "@/components/header/NotificationBellComponent.vue";
import SideNavbarForMediumComponent from "@/components/SideNavbarForMediumComponent.vue";
import SideNavbarForSmallComponent from "@/components/SideNavbarForSmallComponent.vue";
import SearchComponent from "@/components/header/SearchComponent.vue";
import BottomNavigationAuthComponent from "@/components/header/BottomNavigationAuthComponent.vue";
import BottomNavigationNoAuthComponent from "@/components/header/BottomNavigationNoAuthComponent.vue";
import FooterRow from "@/components/FooterRow.vue";

export default {
    name: "HeaderRow",
    components: {
        BottomNavigationNoAuthComponent,
        BottomNavigationAuthComponent,
        SearchComponent,
        SideNavbarForSmallComponent,
        SideNavbarForMediumComponent,
        NotificationBellComponent,
        LanguageSwitcherComponent,
        FooterRow
    },
    data() {
        return {
            url: process.env.VUE_APP_API_URL,
            densityValue: 'default',
            drawer: false,
        }
    },
    methods: {
        changeDrawer() {
            this.drawer = false
        },
        handleResize() {
            const windowWidth = window.innerWidth

            if (windowWidth >= 960) {
                this.densityValue = "default";
            } else {
                this.densityValue = "comfortable";
            }
        },
    },
    computed: {
        ...mapGetters(['isAuthorized', 'getUser'])
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize()
        document.querySelector('.v-toolbar__append').style.marginInlineStart = "10px";
    }
}
</script>

<style scoped>
.title {
    color: #fa1111;
}

.title:hover {
    background: rgba(232, 232, 232, 0.4);
    border-radius: 5px;
}

.login_title {
    letter-spacing: 1.1px;
}

.search_div {
    width: 52%;
    max-width: 700px;
}

.v-main {
    padding-bottom: 0 !important;
}

@media (max-width: 680px) {
    .pr-4 {
        padding-right: 4px !important;
    }

    .pl-4 {
        padding-left: 4px !important;
    }

    .title {
        font-size: 15px;
    }

    .ml-7 {
        margin-left: 8px !important;
    }

    .mr-2 {
        margin-right: 0 !important;
    }
}
</style>