import postRequest from "@/plugins/requests/postRequest"

export default {
    actions: {
        pushBoughtCompany(context, data) {
            return postRequest('/bought_companies', data, 'updateBoughtCompany', context)
        },
    },

    mutations: {
        updateBoughtCompany(state, company) {
            state.company = company
        },
    },

    state: {
        company: {
            id: 0,
            paymeTransactionId: null,
            planTop: {
                price: null
            }
        },
    },

    getters: {
        getBoughtCompany(state) {
            return state.company
        },
    }
}
