import getRequests from "@/plugins/requests/getRequests";

export default {
    actions: {
        fetchLanguages(context){
            return getRequests('/languages', 'updateLanguages', context)
        },
    },

    mutations: {
        updateLanguages(state, languages) {
            state.languages = languages
        },
    },

    state: {
        languages: {
            models: [],
            totalItems: 0,
        },
    },

    getters: {
        getLanguages(state) {
            return state.languages.models
        },
    }
}
