import postRequest from "@/plugins/requests/postRequest"
export default {
    actions: {
        pushBoughtViewResume(context, data) {
            return postRequest('/bought_view_resumes', data, 'updateBoughtViewResume', context)
        },
    },

    mutations: {
        updateBoughtViewResume(state, boughtViewResume) {
            state.boughtViewResume = boughtViewResume
        },
    },

    state: {
        boughtViewResume: {
            id: 0,
            paymeTransactionId: null,
            planView: {
                price: null
            }
        },
    },

    getters: {
        getBoughtViewResume(state) {
            return state.boughtViewResume
        },
    }
}
