import postRequest from "@/plugins/requests/postRequest";
import putRequest from "@/plugins/requests/putRequest";
import deleteRequest from "@/plugins/requests/deleteRequest";
import axios from "@/plugins/vuex/axios";

export default {
    actions: {
        fetchMessages(context, data = {}) {
            return new Promise((resolve, reject) => {
                axios.get(`/messages?page=${data.page}&chat-id=${data.chatId}`)
                    .then((response) => {
                        console.log(response)

                        let messages = {
                            models: response.data.messages['hydra:member'],
                            totalItems: response.data['$messagesCount'],
                            itemsPage: response.data['$pagesCount']
                        }

                        context.commit('updatedMessages', messages)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        pushMessage(context, data) {
            return postRequest('/messages', data, 'empty', context)
        },
        editMessageAction(context, message,) {
            return putRequest('/messages/' + message.id, message.message, 'empty', context)
        },
        deletedMessage(context, message) {
            return deleteRequest('/messages/' + message, context)
        },
    },
    mutations: {
        updatedMessages(state, messages) {
            state.messages = messages
        },
        empty() {
        }
    },
    state: {
        messages: {
            models: [],
            totalItems: 0,
            itemsPage: 0
        }
    },
    getters: {
        getMessages(state) {
            return state.messages.models.reverse()
        },
        getMessagesPagesCount(state) {
            return state.messages.itemsPage
        }
    }
}
