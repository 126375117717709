import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import getRequest from "@/plugins/requests/getRequest";

export default {
    actions: {
        fetchCities(context, filterProps = {}){
            let cityUrl = ''

            if (filterProps.name) {
                cityUrl = '&name=' + filterProps.name
            }

            if (filterProps.user) {
                cityUrl += '&user=' + filterProps.user
            }

            return getRequests('/cities?page=' + filterProps.page + cityUrl , 'updateCities', context)
        },
        createCity(context, data) {
            return postRequest('/cities', data, 'updateCity', context)
        },
        fetchCityByName(context, data) {
            return getRequest('/cities/by_name?name=' + data, 'updateCity', context)
        }
    },

    mutations: {
        updateCities(state, cities) {
            state.cities = cities
        },
        updateCity(state, city) {
            state.city = city
        },
    },

    state: {
        cities: {
            models: [],
            totalItems: 0,
        },
        city: null
    },

    getters: {
        getCities(state) {
            return state.cities.models
        },
        getCityByName(state) {
            return state.city
        }
    }
}
