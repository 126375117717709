import getRequests from "@/plugins/requests/getRequests";
import putRequest from "@/plugins/requests/putRequest";
import deleteRequest from "@/plugins/requests/deleteRequest";

export default {
    actions: {
        fetchResumeJobs(context, resumeId) {
            return getRequests('/resumejobs?resume=' + resumeId, 'updateResumeJobs', context)
        },
        editResumeJob(context, resumejob) {
            return putRequest('/resumejobs/' + resumejob.id, resumejob, 'updateResumeJob', context)
        },
        deleteResumeJob(context, resumeJobId) {
            return deleteRequest('/resumejobs/' + resumeJobId)
        }
    },

    mutations: {
        updateResumeJobs(state, resumeJobs) {
            state.resumeJobs = resumeJobs
        },
        updateResumeJob() {
        },
    },

    state: {
        resumeJobs: {
            models: [],
            totalItems: 0,
        },
    },

    getters: {
        getResumeJobs(state) {
            return state.resumeJobs
        },
    }
}
