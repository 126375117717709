import axios from "@/plugins/vuex/axios";

export default {
    actions: {
        fetchCbuRates(context) {
            return new Promise((resolve, reject) => {
                axios.post('https://cbu.uz/oz/arkhiv-kursov-valyut/json/')
                    .then((response) => {
                        console.log(response)

                        context.commit('updateCbuRates', response.data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        }
    },
    mutations: {
        updateCurrency(state, currencies) {
            state.currencies = currencies
        }
    },
    state: {
        currencies: []
    },
    getters: {
        getCbuRates(state) {
            return state.currencies
        }
    }
}
