<template>
    <div class="d-flex justify-space-around">
        <VMenu offset-y>
            <template v-slot:activator="{ props }">
                <VBtn color="red" v-bind="props" append-icon="mdi-chevron-down">
                    <img class="flags" :src="`/locale_flags/locale_${locale}.svg`" alt="">
                    {{ locale.toUpperCase() }}
                </VBtn>
            </template>
            <VList density="comfortable">
                <VListItem
                    v-for="(l, index) in locales"
                    :key="index"
                    :value="index"
                    :class="{'selected': locale === l }"
                    @click="changeLocale(l)"
                >
                    <VListItemTitle class="select-none">
                        <img class="flags" :src="`@/locale_flags/locale_${l}.svg`" alt="">
                        {{ l.toUpperCase() }}
                    </VListItemTitle>
                </VListItem>
            </VList>
        </VMenu>
    </div>
</template>

<script>
import i18n from '@/locales/i18n'

export default {
    name: "LanguageSwitcherComponent",
    data() {
        return {
            locales: ['uz', 'ru', 'en'],
            locale: localStorage.getItem('locale') || 'uz'
        }
    },
    methods: {
        changeLocale(l) {
            this.locale = l
            localStorage.setItem('locale', l)
            i18n.locale = l
        }
    },
    watch: {
        locale() {
            this.$i18n.locale = this.locale;
        }
    }
}
</script>

<style scoped>
.flags {
    width: 22px;
    height: 12px;
    position: relative;
    left: 0;
    margin-right: 4px;
}

.v-list-item-title {
    position: absolute;
    bottom: 8px;
}

.selected {
    background: rgba(239, 83, 80, 0.2);
}

@media (max-width: 680px) {
    .v-btn--size-default {
        padding: 0 8px !important;
    }
}

</style>