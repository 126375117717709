import getRequests from "@/plugins/requests/getRequests";

export default {
    actions: {
        fetchCurrencies(context){
            return getRequests('/currencies?page=1', 'updateCurrencies', context)
        },
    },

    mutations: {
        updateCurrencies(state, currencies) {
            state.currencies = currencies
        },
    },

    state: {
        currencies: {
            models: [],
            totalItems: 0,
        },
    },

    getters: {
        getCurrencies(state) {
            return state.currencies.models
        },
    }
}
