import getRequests from "@/plugins/requests/getRequests";
import getRequest from "@/plugins/requests/getRequest";
import postRequest from "@/plugins/requests/postRequest";
import deleteRequest from "@/plugins/requests/deleteRequest";
import putRequest from "@/plugins/requests/putRequest";
import i18n from "@/locales/i18n"
import { useToast } from 'vue-toastification'
const toast = useToast()


export default {
    actions: {
        fetchCompanies(context, filterProps = {}) {
            let companyUrl = '';

            if (filterProps.name) {
                companyUrl += '&name=' + filterProps.name;
            }

            if (filterProps.user) {
                companyUrl += '&user=' + filterProps.user;
            }

            if (filterProps.isAdmin) {
                companyUrl += '&isAdmin=' + filterProps.isAdmin;
            }

            return getRequests('/companies?page=' + filterProps.currentPage + companyUrl, 'updateCompanies', context)
        },
        fetchMyCompanies(context, page= 1) {
            return getRequests('/companies/my?page=' + page, 'updateMyCompanies', context)
        },
        fetchCompaniesItemsCount(context, isAdmin = false) {
            let companyUrl = '';

            if (isAdmin) {
                companyUrl += '?isAdmin=' + isAdmin;
            }

            return getRequest('/companies/itemsCount' + companyUrl, 'updateCompaniesItemsCount', context)
        },
        fetchCompany(context, companyId) {
            return getRequest('companies/' + companyId, 'updateCompany', context)
        },
        fetchMyCompany(context, companyId) {
            return getRequest('/companies/'+ companyId + '/my', 'updateCompany', context)
        },
        pushCompany(context, data) {
            return postRequest('/companies', data, 'updateCompany', context)
                .then(() => {
                    toast.success(i18n.global.t('publicStatus0'), {
                        timeout: 3000,
                    });
                })
        },
        deleteCompany(context, companyId) {
            return deleteRequest('companies/' + companyId)
        },
        editCompany(context, company) {
            return putRequest('/companies/' + company.id , company, 'updateCompany', context)
                .then(() => {
                    toast.success(i18n.global.t('publicStatus0'), {
                        timeout: 3000,
                    });
                })
        },
        fetchCompanyArchives(context) {
            return getRequests('/companies/archived', 'updateCompanyArchives', context)
        },

        editArchiveCompany(context, companyId) {
            return putRequest('/companies/'+ companyId + '/is_archive', {}, 'updateCompany', context)
        },
    },

    mutations: {
        updateCompanies(state, companies) {
            state.companies = companies
        },
        updateCompaniesItemsCount(state, companies) {
            state.companiesItemsCount = companies
        },
        updateMyCompanies(state, myCompanies) {
            state.myCompanies = myCompanies
        },
        updateCompany(state, company) {
            state.company = company
        },
        updateCompanyArchives(state, companyArchives) {
            state.companyArchives = companyArchives
        },
    },

    state: {
        companies: {
            models: [],
            totalItems: 0,
            itemsPage: 0
        },
        companiesItemsCount: {
            companiesCount: 0,
            vacanciesCount: 0,
            resumesCount: 0
        },
        company: {
            id: 0,
            name: null,
            createdAt: null,
            vacancies: [],
            description: null,
            image: null,
            phone: null,
            site:'',
            user: {},
            city: {},
            availableVacancies: null,
            publicStatus: 0
        },
        companyArchives: {
            models: [],
            totalItems: 0,
            itemsPage: 0
        },
        myCompanies: {
            models: [],
            totalItems: 0,
            itemsPage: 0
        },
    },

    getters: {
        getCompanies(state) {
            return state.companies
        },
        getMyCompanies(state) {
            return state.myCompanies
        },
        getCompaniesItemsCount(state) {
            return state.companiesItemsCount
        },
        getCompany(state) {
            return state.company
        },
        getCompanyArchives(state) {
            return state.companyArchives
        },
    }
}