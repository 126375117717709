<template>
    <div class="d-flex justify-space-around">
        <VMenu offset-y>
            <template v-slot:activator="{ props }">
                <VBtn
                    color="red"
                    v-bind="props"
                    icon
                    @click="changeRouterPut"
                >
                    <VIcon :class="(getNewOffersCount || getChats.totalUnSeenNumber) ? 'text-red bell' : 'bell'">
                        {{ (getNewOffersCount || getChats.totalUnSeenNumber) ? 'mdi-bell-badge-outline' : 'mdi-bell-outline'}}
                    </VIcon>
                </VBtn>
            </template>
            <VList density="comfortable">
                <VListItem
                    v-for="(item, index) in items"
                    :key="index"
                    :value="index"
                    class="position-relative"
                >
                    <router-link :to="item.to">
                        <VListItemTitle class="select-none mr-3">
                            <VIcon class="list_icon mr-2">{{ item.icon }}</VIcon>
                            <div class="position-absolute status_count font-weight-bold">
                                <v-badge v-if="getChats.totalUnSeenNumber && index === 0" :content="getChats.totalUnSeenNumber" color="#F44336D7"></v-badge>
                                <v-badge v-if="getNewOffersCount && index === 1" :content="getNewOffersCount" color="#F44336D7"></v-badge>
                            </div>
                            {{ $t(item.title) }}
                        </VListItemTitle>
                    </router-link>
                </VListItem>
            </VList>
        </VMenu>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
    name: "NotificationBellComponent",
    data() {
        return {
            items: [
                {title: 'messages', icon: 'mdi-email-outline', to: '/chats'},
                {title: 'suggestions', icon: 'mdi-notebook-check-outline', to: '#'}
            ],
            newOffersCount: 0
        }
    },
    computed: {
        ...mapGetters(['isEmployer', 'getNewOffersCount', 'getChats']),
    },
    methods: {
        ...mapActions(['fetchNewResumeSendsCount', 'fetchNewJobOffersCount', 'fetchAboutMe']),
        changeLocale(l) {
            this.locale = l
        },
        changeRouterPut() {
            this.items[1].to = this.isEmployer ? '/my-vacancy-offers' : '/my-resume-offers'
        },
    },
    mounted() {
        this.fetchAboutMe()
            .then(() => {
                if (this.isEmployer) {
                    this.fetchNewResumeSendsCount()
                } else {
                    this.fetchNewJobOffersCount()
                }
            })
    }
}
</script>

<style scoped>
.bell {
    font-size: 36px;
    color: rgba(0, 0, 0, 0.55);
}

.list_icon {
    font-size: 28px;
    color: rgb(244, 67, 54);
}

.status_count {
    top: -7px;
    left: 46px;
}
</style>