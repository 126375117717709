import postRequest from "@/plugins/requests/postRequest"

export default {
    actions: {
        addViewedResume(context, viewedResume) {
            return postRequest('/viewed_resumes', viewedResume, 'updateViewedResume', context)
        }
    },

    mutations: {
        updateViewedResume() {}
    },
    state: {},
    getters: {}
}
