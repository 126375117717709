import postRequest from "@/plugins/requests/postRequest";

export default {
    actions: {
        createPublicStatus(context, data) {
            return postRequest('/public_statuses', data, 'updatePublicStatus', context)
        },
    },

    mutations: {
        updatePublicStatus(state, publicStatus) {
            state.publicStatus = publicStatus
        },
    },

    state: {
        publicStatus: {
            id: 0,
            status: 0
        },
    },

    getters: {
        getPublicStatus(state) {
            return state.publicStatus
        },
    }
}
