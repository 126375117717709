import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";

export default {
    actions: {
        fetchCheckUser(context, userId) {
            return getRequests('/black_lists/check_user?userId=' + userId, 'updateCheckUser', context)
        },
        fetchCheckParticipant(context, filterProps = {}) {
            let blackListUrl = '';

            if (filterProps.participantId) {
                blackListUrl = '?participantId=' + filterProps.participantId;
            }

            if (filterProps.chatId) {
                blackListUrl += '&chatId=' + filterProps.chatId;
            }

            return getRequests('/black_lists/check_participant' + blackListUrl, 'updateCheckParticipant', context)
        },
        pushBlockParticipant(context, data) {
            return postRequest('/black_lists', data, 'updateBlackList', context)
        },
    },
    mutations: {
        updateCheckUser(state, checkUser) {
            state.checkUser = checkUser
        },
        updateCheckParticipant(state, checkParticipant) {
            state.checkParticipant = checkParticipant
        },
        updateBlackList() {},
    },
    state: {
        checkUser: {
            models: [],
            totalItems: 0,
        },
        checkParticipant: {
            models: [],
            totalItems: 0,
        },
    },
    getters: {
        getCheckUser(state) {
            return state.checkUser
        },
        getCheckParticipant(state) {
            return state.checkParticipant
        },
    }
}
