import getRequest from "@/plugins/requests/getRequest";

export default {
    actions: {
        fetchCurrencyRate(context) {
            return getRequest('/currency_rates/1', 'updateCurrencyRate', context)
        }
    },
    mutations: {
        updateCurrencyRate(state, currencyRate) {
            state.currencyRate = currencyRate
        }
    },
    state: {
        currencyRate: {
            id: null,
            usdToUzs: null,
            rubToUzs: null,
            usdToRub: null
        }
    },
    getters: {
        getCurrencyRate(state) {
            return state.currencyRate
        }
    }
}
