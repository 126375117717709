import {createStore} from "vuex"
import blackList from "@/plugins/vuex/modules/blackList"
import user from "@/plugins/vuex/modules/user"
import resume from "@/plugins/vuex/modules/resume"
import resumeJob from "@/plugins/vuex/modules/resumeJob"
import chat from "@/plugins/vuex/modules/chat"
import company from "@/plugins/vuex/modules/company"
import currencyRate from "@/plugins/vuex/modules/currencyRate"
import vacancy from "@/plugins/vuex/modules/vacancy"
import tag from "@/plugins/vuex/modules/tag"
import city from "@/plugins/vuex/modules/city"
import selectedResume from "@/plugins/vuex/modules/selectedResume"
import selectedVacancy from "@/plugins/vuex/modules/selectedVacancy"
import resumeSend from "@/plugins/vuex/modules/resumeSend"
import mediaObject from "@/plugins/vuex/modules/mediaObject"
import message from "@/plugins/vuex/modules/message"
import jobOffer from "@/plugins/vuex/modules/jobOffer"
import language from "@/plugins/vuex/modules/language"
import viewedResume from "@/plugins/vuex/modules/viewedResume"
import planTop from "@/plugins/vuex/modules/planTop"
import planType from "@/plugins/vuex/modules/planType"
import boughtCompany from "@/plugins/vuex/modules/boughtCompany"
import boughtViewResume from "@/plugins/vuex/modules/boughtViewResume"
import planView from "@/plugins/vuex/modules/planView"
import publicStatus from "@/plugins/vuex/modules/publicStatus"
import boughtTopCompany from "@/plugins/vuex/modules/boughtTopCompany"
import boughtVacancy from "@/plugins/vuex/modules/boughtVacancy"
import others from "@/plugins/vuex/modules/others"
import currency from "@/plugins/vuex/modules/currency"
import viewedVacancy from "@/plugins/vuex/modules/viewedVacancy";

export default createStore({
    modules: {
        blackList,
        boughtCompany,
        boughtViewResume,
        boughtTopCompany,
        boughtVacancy,
        resumeSend,
        chat,
        city,
        company,
        currencyRate,
        planTop,
        user,
        resume,
        resumeJob,
        selectedResume,
        selectedVacancy,
        vacancy,
        viewedResume,
        tag,
        mediaObject,
        message,
        jobOffer,
        language,
        planType,
        planView,
        publicStatus,
        others,
        currency,
        viewedVacancy
    }
})