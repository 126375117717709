import getRequests from "@/plugins/requests/getRequests"
import postRequest from "@/plugins/requests/postRequest"
import i18n from "@/locales/i18n"
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    actions: {
        fetchSelectedResumes(context, resumesId = []) {
            let url = ''

            if (resumesId) {
                resumesId.map(item => {
                    url += '&resume.id[]=' + item
                })
            }

            return getRequests('/selected_resumes?page=1' + url, 'updateSelectedResumes', context)
        },
        fetchSelectedResume(context, resumeId) {
            return getRequests('/selected_resumes?resume.id=' + resumeId, 'updateSelectedResume', context)
        },
        pushSelectedResume(context, resumeId) {
            return postRequest('/selected_resumes', resumeId, 'addSelectedResume', context)
                .catch((error) => {
                    if (error.response.status === 400) {
                        toast.error(i18n.global.t("resume.cantMarkAsFavoriteCv"))
                    }
                })
        },
        fetchMySelectedResumes(context, page = 1) {
            return getRequests('/selected_resumes/my?page=' + page, 'updateMySelectedResumes', context)
        }
    },

    mutations: {
        updateSelectedResumes(state, selectedResumes) {
            state.selectedResumes = selectedResumes
        },
        updateSelectedResume(state, selectedResume) {
            state.selectedResume = selectedResume
        },
        addSelectedResume() {},
        updateMySelectedResumes(state, mySelectedResumes) {
            state.mySelectedResumes = mySelectedResumes
        }
    },

    state: {
        selectedResumes: {
            models: [],
            totalItems: 0
        },
        selectedResume: {
            models: [],
            totalItems: 0
        },
        mySelectedResumes: {
            models: [],
            totalItems: 0,
            itemsPage: 0
        },
    },

    getters: {
        getSelectedResumes(state) {
            return state.selectedResumes.models
        },
        getSelectedResumesCount(state) {
            return state.selectedResumes.totalItems
        },
        getSelectedResume(state) {
            return state.selectedResume
        },
        getMySelectedResumes(state) {
            return state.mySelectedResumes
        }
    }
}