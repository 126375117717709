<template>
    <v-app>
        <HeaderRow>
            <router-view/>
        </HeaderRow>
    </v-app>
</template>

<script>
import HeaderRow from "@/components/header/HeaderRow.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: 'App',
    components: {HeaderRow},
    data() {
        return {
            mercureUrl: process.env.VUE_APP_MERCURE_URL,
        }
    },
    computed: {
        ...mapGetters(['isAuthorized', 'getRefreshToken', 'getUser', 'isEmployer', 'getUserInfo'])
    },
    methods: {
        ...mapActions([
            'fetchAboutMe',
            'refreshToken',
            'fetchChats',
            'changeUserStatus',
            'fetchNewResumeSendsCount',
            'fetchNewJobOffersCount',
            'fetchUserInfo'
        ]),
        ...mapMutations(['clearTokens']),
        connectMercure() {
            const url = new URL(this.mercureUrl)
            url.searchParams.append('topic', 'chats')
            this.eventSource = new EventSource(url)

            this.eventSource.addEventListener('message', async (event) => {
                await this.addNewMessageToArray(event)
            })
        },
        addNewMessageToArray(event) {
            if (this.isAuthorized) {
                if (JSON.parse(event.data).status) {
					this.fetchChats(1)

                    if (typeof this.getUserInfo.id === 'number') {
                        this.fetchUserInfo(this.getUserInfo.id)
                    }

                    if (this.isEmployer) {
                        this.fetchNewResumeSendsCount()
                    } else {
                        this.fetchNewJobOffersCount()
                    }
                }
            }
        }
    },
    mounted() {
        this.connectMercure()

        if (this.isAuthorized) {
            this.fetchAboutMe()
                .then(() => {
                    this.changeUserStatus({id: this.getUser.id, status: true})
                    window.addEventListener('beforeunload', () => {
                        this.changeUserStatus({id: this.getUser.id, status: false})
                    })
                })
                .catch(() => {
                    this.refreshToken(this.getRefreshToken)
                        .then(() => {
                            this.fetchAboutMe()
                                .then(() => {
                                    this.changeUserStatus({id: this.getUser.id, status: true})
                                    window.addEventListener('beforeunload', () => {
                                        this.changeUserStatus({id: this.getUser.id, status: false})
                                    })

                                })
                        })
                        .catch(() => {
                            this.clearTokens()
                            this.$router.push('/login')
                        })
                })
        }
    }
}
</script>

<style>
a {
    color: inherit;
    text-decoration: none;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor_default {
    cursor: default;
}

.select-none {
    user-select: none;
}

.link {
    color: dodgerblue;
    cursor: pointer;
}

.date {
    color: #878787;
    font-size: 14px;
}

</style>
