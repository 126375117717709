<template>
    <hr/>
    <div class="px-5 pt-5 pb-3 px-sm-9 pt-sm-9 pb-sm-7 px-md-15 pt-md-12 pb-md-10">
        <v-row no-gutters class="d-md-flex justify-md-flex-start">
            <v-col cols="12" sm="6" md="3">
                <ul class="footer-col">
                    <li class="text-red-lighten-1 font-weight-black first-link">
                        itwork.uz
                    </li>
                    <li class="mb-2">
                        <router-link class="footer-link" to="/about-us">{{ $t('aboutUs') }}</router-link>
                    </li>
                    <li class="mb-2">
                        <router-link class="footer-link" to="/about-us">{{ $t('contactUs') }}</router-link>
                    </li>
                    <li class="mb-2">
                        <router-link class="footer-link" to="/about-us">{{ $t('ourPartners') }}</router-link>
                    </li>
                    <li class="mb-2">
                        <router-link class="footer-link" to="/about-us">{{ $t('forInvestors') }}</router-link>
                    </li>
                </ul>
            </v-col>
            <v-col v-if="!isAuthorized || getUser.isEmployer" class="mt-4 mt-sm-0" cols="12" sm="6" md="3">
                <ul class="footer-col">
                    <li class="text-red-lighten-1 font-weight-black first-link">
                        {{ $t('company.forCompany') }}
                    </li>
                    <li v-if="getUser.isEmployer" class="mb-2">
                        <router-link class="footer-link" to="/my-companies">{{ $t('company.myCompanies') }}</router-link>
                    </li>
                    <li class="mb-2">
                        <router-link class="footer-link" to="/add-company">{{ $t('company.addCompany') }}</router-link>
                    </li>
                    <li v-if="getUser.isEmployer" class="mb-2">
                        <router-link class="footer-link" to="/my-vacancies">{{ $t('vacancy.myVacancies') }}</router-link>
                    </li>
                    <li class="mb-2">
                        <router-link class="footer-link" to="/add-vacancy">{{ $t('vacancy.addVacancy') }}</router-link>
                    </li>
                    <li class="mb-2">
                        <router-link class="footer-link" to="/resumes">{{ $t('resume.resumes') }}</router-link>
                    </li>
                </ul>
            </v-col>
            <v-col v-if="!getUser.isEmployer" class="mt-4 mt-sm-0" cols="12" sm="6" md="3">
                <ul class="footer-col">
                    <li class="text-red-lighten-1 font-weight-black first-link">
                        {{ $t('forSpecialist') }}
                    </li>
                    <li v-if="isAuthorized && !getUser.isEmployer" class="mb-2">
                        <router-link class="footer-link" to="/my-resumes/1">{{ $t('resume.myResumes') }}</router-link>
                    </li>
                    <li class="mb-2">
                        <router-link class="footer-link" to="/add-resume">{{ $t('resume.addResume') }}</router-link>
                    </li>
                    <li class="mb-2">
                        <router-link class="footer-link" to="/companies">{{ $t('company.companies') }}</router-link>
                    </li>
                    <li class="mb-2">
                        <router-link class="footer-link" to="/vacancies/1">{{ $t('vacancy.vacancies') }}</router-link>
                    </li>
                </ul>
            </v-col>
            <v-col class="mt-4 mt-sm-0 mb-6 mb-md-0" cols="12" sm="6" md="3">
                <div class="address mb-6">
                    <div class="pe-5">
                        <span class="mt-1 d-block d-sm-inline-block mr-1 text-black-lighten-1 font-weight-bold">
                            {{ $t('address') }}
                        </span>
                        <span class="d-block d-sm-inline-block"> {{ $t('ourAddress') }}</span>
                    </div>
                    <div>
                        <span class="mb-2 mt-1 d-sm-inline-block mr-1 text-black-lighten-1 font-weight-bold">
                            {{ $t('aboutMe.telephone') }}:
                        </span>
                        <span>+99890 298 97 07</span>
                    </div>
                    <div>
                        <span class="mt-1 d-sm-inline-block mr-1 text-black-lighten-1 font-weight-bold">
                            {{ $t('aboutMe.email') }}:
                        </span>
                        <span>itwork.com</span>
                    </div>
                    <div v-if="!isAuthorized">
                        <span class="mt-1 d-block d-sm-inline-block position-absolute mr-1 text-black-lighten-1 font-weight-bold">
                            {{ $t('menu.onGooglePlay') }}
                            <a href="#" class="google-icon">
                                <v-img width="100" src="@/assets/img/google-play.png" alt=""/>
                            </a>
                        </span>
                    </div>
                </div>
            </v-col>
            <v-col v-if="isAuthorized" class="mt-sm-0 mb-md-0" cols="12" sm="6" md="3">
                <div class="address">
                    <div>
                        <span class="mt-1 d-block d-sm-inline-block mr-1 text-black-lighten-1 font-weight-bold">
                            {{ $t('menu.footerMessage') }}
                        </span>
                        <a href="#">
                            <v-img width="100" style="margin-left: -7px" src="@/assets/img/google-play.png" alt=""></v-img>
                        </a>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>

    <hr/>

    <footer class="px-5 pt-6 pb-16 px-sm-9 pt-sm-4 pb-sm-6 px-md-15 pt-md-6 pb-md-10 mb-5 mb-sm-0">
        <div>
            <span>{{ $t('onTheSiteToday') }}</span>
            <span class="ml-6 ml-sm-0 d-block d-sm-inline-block mr-1">
                {{ getCompaniesItemsCount.vacanciesCount }} <span v-if="$i18n.locale === 'uz'">ta</span> {{ $t('vacancy.vacancies') }},
            </span>
            <span class="ml-6 ml-sm-0 d-block d-sm-inline-block mr-1">
                {{ getCompaniesItemsCount.resumesCount }} <span v-if="$i18n.locale === 'uz'">ta</span> {{ $t('resume.resumes') }},
            </span>
            <span class="ml-6 ml-sm-0 d-block d-sm-inline-block mr-1">
                {{ getCompaniesItemsCount.companiesCount }} <span v-if="$i18n.locale === 'uz'">ta</span> {{ $t('company.companies') }}
            </span>
            <span v-if="$i18n.locale === 'uz'">mavjud</span>
        </div>
        <div class="d-md-flex justify-md-space-between align-center mb-md-4">
            <div>
                <span>{{ $t('allRights') }} </span>
                <span class="d-inline-block font-weight-medium">© 2024 itwork.uz</span>
            </div>
            <div class="footer_row d-flex align-center my-sm-1 my-md-0">
                {{ $t('followUs') }}
                <a class="mx-1 mx-sm-3" target="_blank" href="https://www.facebook.com/It-work-107821581798358">
                    <v-icon size="35" large color="blue">mdi-facebook</v-icon>
                </a>
                <a class="mr-1 mr-sm-3" target="_blank" href="https://www.instagram.com/itwork_uz/">
                    <v-icon size="35" large color="purple">mdi-instagram</v-icon>
                </a>
                <a class="mr-1 mr-sm-3" target="_blank" href="https://t.me/itwork_uz">
                    <v-icon size="35" large color="blue">mdi-telegram</v-icon>
                </a>
            </div>
        </div>
    </footer>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "FooterRow",
    data() {
        return {
            isAdmin: false
        }
    },
    computed: {
        ...mapGetters(['getCompaniesItemsCount', 'getUser', 'isAuthorized'])
    },
    methods: {
        ...mapActions(['fetchCompaniesItemsCount', 'fetchAboutMe']),
    },
    mounted() {
        if (this.isAuthorized) {
            this.fetchAboutMe()
                .then(() => {
                    if (this.getUser.roles.includes('ROLE_ADMIN')) {
                        this.isAdmin = true
                    }

                    this.fetchCompaniesItemsCount(this.isAdmin)
                })
        } else {
            this.fetchCompaniesItemsCount(this.isAdmin)
        }
    }
}
</script>

<style scoped>
.footer-col {
    list-style: none;
}

.first-link {
    font-size: 20px;
    margin-bottom: 16px;
}

.footer-link:hover {
    cursor: pointer;
    color: #F44336;
}

.address {
    width: 250px;
    height: 150px;
}

@media (max-width: 959px) {
    .first-link {
        margin-bottom: 12px;
    }
}

@media only screen and (max-width: 400px) {
    .footer_row {
        justify-content: space-between !important;
    }
}

.google-icon {
    position: absolute;
    bottom: -7px;
}
</style>
