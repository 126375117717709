import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import putRequest from "@/plugins/requests/putRequest";
import getRequest from "@/plugins/requests/getRequest";

export default {
    actions: {
        fetchJobOffers(context, filterProps = {}){
            let jobOfferUrl = '&order%5Bid%5D=desc';

            if (filterProps.resume) {
                jobOfferUrl += '&resume=' + filterProps.resume;
            }

            return getRequests('/job_offers?page=' + filterProps.page + jobOfferUrl, 'updateJobOffers', context)
        },
        fetchJobOffersResponse(context){
            return getRequests('/job_offers/response', 'updateJobOffersResponse', context)
        },
        addJobOffer(context, jobOffer){
            return postRequest('/job_offers', jobOffer, 'updateJobOffer', context)
        },
        jobOfferUpdate(context, jobOffer){
            return putRequest('/job_offers/' + jobOffer.id, jobOffer, 'updateJobOffer', context)
        },
        fetchJobOffer(context, jobOfferId) {
            return getRequest('/job_offers/' + jobOfferId, 'updateJobOffer', context)
        }
    },

    mutations: {
        updateJobOffers(state, jobOffers) {
            state.jobOffers = jobOffers
        },
        updateJobOffersResponse(state, jobOffersResponse) {
            state.jobOffersResponse = jobOffersResponse
        },
        updateJobOffer(state, jobOffer) {
            state.jobOffer = jobOffer
        },
    },

    state: {
        jobOffers: {
            models: [],
            totalItems: 0,
        },
        jobOffersResponse: {
            models: [],
            totalItems: 0,
        },
        jobOffer:{
            id: 0,
            text: '',
            status: 0,
            resume: {
                id: 0,
                name: '',
                wantedSalary: 0,
                user: {
                    id: 0,
                    givenName: '',
                    familyName: ''
                },
                city: {
                    id: 0,
                    name: ''
                },
                tags: []
            },
            vacancy: {
                id: 0,
                name: '',
                minSalary: 0,
                maxSalary: 0,
                currency: {},
                company: {},
                user: {
                    id: 0,
                    givenName: '',
                    familyName: ''
                },
                city: {
                    id: 0,
                    name: ''
                },
                tags: []
            }
        },
    },

    getters: {
        getJobOffers(state) {
            return state.jobOffers
        },
        getJobOffersResponse(state) {
            return state.jobOffersResponse
        },
        getJobOffer(state) {
            return state.jobOffer
        },
    }
}
