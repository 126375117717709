import getRequests from "@/plugins/requests/getRequests";

export default {
    actions: {
        fetchPlanTops(context){
            return getRequests('/plan_tops', 'updatePlanTop', context)
        },
    },

    mutations: {
        updatePlanTop(state, planTops) {
            state.planTops = planTops
        },
    },

    state: {
        planTops: {
            models: [],
            totalItems: 0,
        },
    },

    getters: {
        getPlanTops(state) {
            return state.planTops.models
        },
    }
}
