import getRequests from "@/plugins/requests/getRequests"

export default {
    actions: {
        fetchPlanViews(context){
            return getRequests('/plan_views', 'updatePlanViews', context)
        },
    },

    mutations: {
        updatePlanViews(state, planViews) {
            state.planViews = planViews
        },
    },

    state: {
        planViews: {
            models: [],
            totalItems: 0,
        },
    },

    getters: {
        getPlanViews(state) {
            return state.planViews.models
        },
    }
}
